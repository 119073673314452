import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { RiCircleLine, RiCircleFill, RiPauseCircleLine, RiPlayCircleLine } from '@remixicon/react'

import Slider, { CustomArrowProps } from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'
import { ISlideProps } from '../../components/HeroSlider2/types'
import HeroArrowCustom from '../../components/HeroSlider2/HeroArrow'
import { InnerDot } from '~/componentsV2/carousel/styles'
import Slide from '../../components/HeroSlider2/Slide'
import { HeroSliderWrapper } from '../../components/HeroSlider2/styles'
import AntiqueRugsProductItem from '~/components/product-item-antique-rugs'
import { TabContent, TabContentButton, TabItem, TabNavigation, Wrapper } from './styles'
import { PageContainer } from '../../components/PageContainer'
import { IPageBackground, IPageContainer, IPageCta } from '../../types'
import ResposiveImage, { IMedia } from '~/componentsV2/ResposiveImage'
import ImageWrapper from '~/components/image-wrapper'
import { Button } from 'theme-ui'
import { openInquireForm } from '~/components/header/bespoke-header/inquire-form'

interface HeroSliderProps {
  data: {
    container: IPageContainer
    background: IPageBackground
    tabs: {
      id: string
      tab: string
      images: {
        data: Array<
          IMedia & {
            alternativeText?: string
          }
        >
      }
    }[]
    cta: IPageCta
  }
}

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom position="right" {...props} />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom {...props} />
)

const Dot = ({ active }: { active: boolean }) => {
  return (
    <InnerDot>
      <RiCircleLine className={active ? 'active-dot' : 'inactive-dot'} />
    </InnerDot>
  )
}

const SliderTabs: React.FC<HeroSliderProps> = props => {
  const { container, background, tabs } = props?.data
  const [currentTab, setCurrentTab] = useState(0)
  const sliderRef = useRef<Slider>(null)

  const currentTabContent = useMemo(() => {
    const images = tabs[currentTab]?.images?.data
    if (!images) return []

    return [...images].sort((a, b) => {
      const orderA = parseInt(a.caption || '0', 10)
      const orderB = parseInt(b.caption || '0', 10)

      if (!isNaN(orderA) && !isNaN(orderB)) {
        return orderA - orderB
      }

      if (!isNaN(orderA)) return -1
      if (!isNaN(orderB)) return 1

      return 0
    })
  }, [currentTab, tabs])

  if (!tabs) {
    return null
  }

  return (
    <PageContainer container={container} background={background}>
      <Wrapper className="slider-section">
        <TabNavigation className="tabs-wrapper">
          {tabs.map((tab, index) => (
            <TabItem
              key={tab.id}
              className="tab-item"
              data-active={currentTab === index}
              onClick={() => setCurrentTab(index)}
            >
              {tab.tab}
            </TabItem>
          ))}
        </TabNavigation>
        <div className="slider-wrapper">
          <Slider
            ref={sliderRef}
            dots={true}
            infinite={true}
            speed={1000}
            autoplaySpeed={10000}
            slidesToShow={3}
            slidesToScroll={1}
            arrows={true}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
          >
            {currentTabContent.map((image: any) => (
              <TabContent key={image.id}>
                <div className="product-image-wrapper">
                  <ResposiveImage desktop={image} />
                </div>
                <TabContentButton>
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      openInquireForm({
                        product: {
                          title: image.alternativeText,
                        },
                      })
                    }}
                    sx={{
                      border: '1px solid #16283E',
                      background: 'transparent',
                      color: '#16283E',
                      padding: '10px 20px',
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                      fontFamily: 'var(--font-family-sans-serif)',
                    }}
                  >
                    Inquire
                  </Button>
                </TabContentButton>
              </TabContent>
            ))}
          </Slider>
        </div>
      </Wrapper>
    </PageContainer>
  )
}

SliderTabs.displayName = 'SliderTabs'

export default SliderTabs
