import React from 'react'
import { IImageGridProps } from './types'
import {
  ImageGridWrapper,
  ImageGridItem,
  ImageGridItemTitle,
  ImageGridItemContainer,
} from './styles'
import ResposiveImage from '~/componentsV2/ResposiveImage'
import { PageContainer } from '../../components/PageContainer'
import TextBox from '../../components/TextBox'

export const ImageGridSection: React.FC<IImageGridProps> = ({ data }) => {
  const { columns, images, container, background, textPosition, textBox } = data

  return (
    <PageContainer container={container} background={background}>
      <ImageGridItemContainer>
        {textPosition && textPosition === 'top' && <TextBox {...textBox} />}
        <ImageGridWrapper $columns={columns}>
          {images.map(image => (
            <ImageGridItem key={image.id}>
              <ResposiveImage
                desktop={image.desktopImage}
                tablet={image.tabletImage}
                mobile={image.mobileImage}
              />
              {image?.name && (
                <ImageGridItemTitle to={image?.link || ''}>
                  <h3>{image.name}</h3>
                </ImageGridItemTitle>
              )}
            </ImageGridItem>
          ))}
        </ImageGridWrapper>
        {textPosition && textPosition === 'bottom' && <TextBox {...textBox} />}
      </ImageGridItemContainer>
    </PageContainer>
  )
}
